import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { ProgressBarItem, SkillProgressBar } from "@sol/uikit";

type Props = {
    title: string;
    assessment: ProgressBarItem[];
    selfAssessment: ProgressBarItem[];
    className?: string;
} & ComponentProps<"div">;

const SkillEvaluation = ({ title, assessment, selfAssessment, className, ...divProps }: Props) => {
    const [t] = useTranslation();

    return (
        <div {...divProps} className={className}>
            <Text as="h3">{title}</Text>
            <SkillProgressBar
                data-cy="skill-evaluation-assessment"
                title={t("component.shared.SkillEvaluation.assessment")}
                levels={assessment}
            />
            <SkillProgressBar
                data-cy="skill-evaluation-self-assessment"
                title={t("component.shared.SkillEvaluation.selfAssessment")}
                levels={selfAssessment}
            />
        </div>
    );
};

export default styled(SkillEvaluation)`
    ${SkillProgressBar} {
        &:first-of-type {
            margin: ${({ theme }) => theme.spacing[5]} 0;
        }
    }
`;
