import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import {
    IClassroomUser,
    IFrameworkResource,
    IFrameworkSkill,
    IUser,
    IValidationResource,
    ValidationStatus,
} from "@sol/sdk";
import { useFrameworkQuery } from "@sol/sdk/frameworks/queries/frameworkQuery";
import { ProgressBarItem } from "@sol/uikit/progress-bar";

import { Text } from "../uikit";
import ExpansionPanel, { ExpansionPanelDetails, ExpansionPanelSummary } from "../uikit/ExpansionPanel";
import SkillEvaluation from "./shared/SkillEvaluation";
import { BlockSkeleton } from "./skeletons";

export const SkillLevelsValidationsContainer = styled.div<{ isInCard?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    padding: ${({ theme }) => theme.spacing[6]};

    > * {
        ${({ isInCard }) =>
            isInCard
                ? css`
                      width: 100%;
                      margin-bottom: ${({ theme }) => theme.spacing[6]};
                  `
                : css`
                      width: 50%;
                      margin-bottom: ${({ theme }) => theme.spacing[9]};
                      &:nth-child(odd) {
                          width: calc(50% - ${({ theme }) => theme.spacing[7]});
                          margin-right: ${({ theme }) => theme.spacing[7]};
                      }
                  `}
    }
`;

const getProgressBarLevels = (
    skillLevels: IFrameworkSkill["@id"][],
    validations: Pick<IValidationResource, "status" | "skillLevel">[],
    t: TFunction,
) => {
    const progressBarLevels: ProgressBarItem[] = [];

    const getVariant = ({
        status,
        skillLevel,
    }: {
        skillLevel: number;
        status: ValidationStatus;
    }): ProgressBarItem["variant"] => {
        let validatedLevel = 0;
        let unvalidatedLevel = 0;

        if (status === ValidationStatus.VALIDATED && skillLevel > validatedLevel) {
            validatedLevel = skillLevel;
        } else if (skillLevel > unvalidatedLevel) {
            unvalidatedLevel = skillLevel;
        }

        return validatedLevel >= skillLevel ? "success" : unvalidatedLevel >= skillLevel ? "danger" : "default";
    };

    skillLevels.forEach((skillLevelId, index) => {
        const validation = validations.find(v => v.skillLevel["@id"] === skillLevelId);

        if (validation) {
            const skillLevel = validation.skillLevel.level;
            const { status } = validation;

            progressBarLevels.push({
                id: skillLevelId,
                variant: getVariant({ status, skillLevel }),
                title: `${t("component.SkillLevel.level")} ${skillLevel}`,
            });
        } else {
            progressBarLevels.push({
                id: skillLevelId,
                variant: "default",
                title: `${t("component.SkillLevel.level")} ${index + 1}`,
            });
        }
    });

    return progressBarLevels;
};

type FrameworkSkillsValidationsProps = {
    className?: string;
    framework: IFrameworkResource;
    validations: IValidationResource[];
    isInCard?: boolean;
    learner: IClassroomUser | IUser;
};

const FrameworkSkillsValidations = ({
    className,
    framework,
    validations,
    isInCard,
    learner,
}: FrameworkSkillsValidationsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [t] = useTranslation();

    const { data: frameworkDetails, isLoading } = useFrameworkQuery({ framework, learner }, { enabled: isOpen });

    const renderSkillEvaluation = (skill: IFrameworkSkill) => {
        const selfEvaluatuedLevels = skill.selfEvaluatedLevels || [0];
        const skillLevels = [skill.skillLevel1, skill.skillLevel2, skill.skillLevel3];
        const highestSelfEvaluatedSkillLevel = Math.max(...selfEvaluatuedLevels);

        const levels = getProgressBarLevels(skillLevels, validations, t);
        const selfAssessment: ProgressBarItem[] = skillLevels.map((level, index) => ({
            id: level,
            variant: highestSelfEvaluatedSkillLevel > index ? "primary" : "default",
            title: `${t("component.SkillLevel.level")} ${index + 1}`,
        }));

        return (
            <SkillEvaluation
                key={skill["@id"]}
                title={`${skill.code} - ${skill.shortTitle}`}
                assessment={levels}
                selfAssessment={selfAssessment}
                data-cy="skill-evaluation"
            />
        );
    };

    return (
        <ExpansionPanel
            htmlTag="li"
            className={className}
            expanded={frameworkDetails && isOpen}
            onExpandedChange={setIsOpen}
        >
            <ExpansionPanelSummary
                srOnlyLabel={t(`component.expansionPanel.${frameworkDetails && isOpen ? "close" : "open"}`, {
                    title: framework.title,
                })}
            >
                <Text variant="label">{framework.title}</Text>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {frameworkDetails ? (
                    <SkillLevelsValidationsContainer isInCard={isInCard}>
                        {isLoading ? <BlockSkeleton /> : frameworkDetails.skills.map(renderSkillEvaluation)}
                    </SkillLevelsValidationsContainer>
                ) : null}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default styled(FrameworkSkillsValidations)``;
