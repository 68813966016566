import { isBefore, parseISO } from "date-fns";
import Image from "next/image";
import Link from "next/link";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import styled from "styled-components";

import { useActiveClassroom } from "@sol/classrooms";
import { Feature, FeatureGuard } from "@sol/features";
import { Calendar, Loading, Open, Pencil, Pin, SvgIcon, Users } from "@sol/icons";
import { Route } from "@sol/routing";
import { IClassroom, getPlaceholderPicture, useClassroomWebinarQuery } from "@sol/sdk";
import { Button } from "@sol/uikit";
import { Grid, GridColumn } from "@sol/uikit/layouts/Grid";

import { CLASSROOM_CARD_COVER_WIDTH } from "../../uikit/theme/imageSizes";
import ApiErrorAlert from "../ApiErrorAlert";
import { BlockSkeleton, IconSkeleton, TextSkeleton } from "../skeletons";

const Infos = styled(Grid).attrs({ as: "ul" })`
    list-style-type: none;
    padding: 0;
`;

const InfoContainer = styled.li`
    display: flex;
    align-items: center;

    > ${SvgIcon} {
        margin-right: ${({ theme }) => theme.spacing[4]};

        --icon-color: ${({ theme }) => theme.palette.purple.base};
    }

    ${IconSkeleton} {
        margin-right: ${({ theme }) => theme.spacing[4]};
    }
`;

const Cover = styled.div`
    width: 100%;
    height: 290px;
    // As next/image add intermediate
    // markup with position absolute
    position: relative;

    display: flex;

    > * {
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const ContentHeader = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing[7]};

    ${Text} + ${Text} {
        margin-top: ${({ theme }) => theme.spacing[7]};
    }

    & > ${TextSkeleton} {
        margin-bottom: ${({ theme }) => theme.spacing[7]};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[7]};
`;

const parseDate = (date: string) => parseISO(date.split("T")[0]);

const checkWebinarAllowed = (classroom: Props["classroom"]) => {
    if (!classroom) {
        return false;
    }

    const today = new Date().toISOString();
    const endDate = classroom.endDate;
    const hasClassroomExpired = isBefore(parseDate(endDate), parseDate(today));

    if (hasClassroomExpired) {
        return false;
    }

    return true;
};

const LoadingClassroomCardContent = ({ className }: { className?: string }) => (
    <Grid className={className}>
        <GridColumn col={6}>
            <BlockSkeleton />
        </GridColumn>
        <GridColumn col={6}>
            <RightColumn>
                <ContentHeader>
                    <TextSkeleton width="70%" height="28px" />
                    <div>
                        <TextSkeleton count={4} width="100%" height="20px" />
                    </div>
                </ContentHeader>
                <Content>
                    <Infos>
                        {[1, 2, 3].map(index => (
                            <GridColumn key={index} as="li" col={4}>
                                <InfoContainer>
                                    <IconSkeleton round height={24} width={24} />
                                    <TextSkeleton height="12px" width="70%" />
                                </InfoContainer>
                            </GridColumn>
                        ))}
                    </Infos>
                </Content>
            </RightColumn>
        </GridColumn>
    </Grid>
);

type Props = {
    classroom?: Pick<
        IClassroom,
        "@id" | "title" | "description" | "startDate" | "endDate" | "location" | "nbLearners" | "image"
    >;
    showVisio?: boolean;
    className?: string;
};

const ClassroomCardContent = ({ classroom, showVisio, className }: Props) => {
    const [t] = useTranslation();
    const { setActiveClassroom, isLoading: isLoadingClassroom } = useActiveClassroom();
    const webinarButtonRef = useRef(null);

    const isWebinarAllowed = useMemo(() => checkWebinarAllowed(classroom), [classroom]);

    const {
        refetch: fetchWebinar,
        isFetching: isWebinarFetching,
        error: webinarFetchError,
        clear: resetWebinar,
    } = useClassroomWebinarQuery({ classroom: classroom?.["@id"] }, { enabled: false, retry: 0 });

    if (isLoadingClassroom || !classroom) {
        return <LoadingClassroomCardContent className={className} />;
    }

    const { "@id": id, title, image, description, startDate, endDate, location, nbLearners } = classroom;

    return (
        <>
            <Grid className={className}>
                <GridColumn col={6}>
                    <Cover>
                        <Image
                            src={image?.url || getPlaceholderPicture(classroom)}
                            width={CLASSROOM_CARD_COVER_WIDTH}
                            height={290}
                            layout="intrinsic"
                            alt=""
                            objectFit="cover"
                            objectPosition="center"
                        />
                    </Cover>
                </GridColumn>
                <GridColumn col={6}>
                    <RightColumn>
                        <ContentHeader>
                            <Text as="h2" variant="h4" lines={2} data-cy="classroom-card-title">
                                {title}
                            </Text>
                            <Text lines={5}>{description}</Text>
                        </ContentHeader>
                        <Content>
                            <Infos>
                                {location && (
                                    <GridColumn as={InfoContainer} col={4}>
                                        <Pin />
                                        <Text variant="subheading">{location.title}</Text>
                                    </GridColumn>
                                )}
                                <GridColumn as={InfoContainer} col={location ? 4 : 6}>
                                    <Users />
                                    <Text variant="subheading">
                                        {t("page.classrooms.home.learners.totalLearners", {
                                            count: nbLearners,
                                        })}
                                    </Text>
                                </GridColumn>{" "}
                                <GridColumn as={InfoContainer} col={location ? 4 : 6}>
                                    <Calendar />
                                    <Text variant="subheading">
                                        {t("page.classrooms.home.classroom.dates", {
                                            startDate: new Date(startDate),
                                            endDate: new Date(endDate),
                                        })}
                                    </Text>
                                </GridColumn>
                            </Infos>
                            <Actions>
                                <div>
                                    <FeatureGuard feature={Feature.CLASSROOM_UPDATE}>
                                        <Link href={Route.CLASSROOMS_EDIT} passHref>
                                            <Button
                                                variant="primary"
                                                outlined
                                                as="a"
                                                title={t("component.classroomCard.editButton.title", {
                                                    classroomTitle: title,
                                                })}
                                                onClick={() => setActiveClassroom(id)}
                                            >
                                                {t("component.classroomCard.editButton.text")}
                                                <Pencil />
                                            </Button>
                                        </Link>
                                    </FeatureGuard>
                                </div>
                                <FeatureGuard feature={Feature.CLASSROOM_WEBINAR}>
                                    {showVisio && isWebinarAllowed ? (
                                        <Button
                                            ref={webinarButtonRef}
                                            data-cy="open-webinar"
                                            variant={"primary"}
                                            filled
                                            title={t("component.classroomCard.visioButton.title", {
                                                classroomTitle: title,
                                            })}
                                            onClick={async () => {
                                                try {
                                                    const webinar = await fetchWebinar();

                                                    if (webinar) {
                                                        window.open(webinar.url, "_blank", "noopener,noreferrer");
                                                    }
                                                } catch {}
                                            }}
                                            loading={isWebinarFetching}
                                        >
                                            {t("component.classroomCard.visioButton.text")}{" "}
                                            {isWebinarFetching ? <Loading /> : <Open />}
                                        </Button>
                                    ) : null}
                                </FeatureGuard>
                            </Actions>
                        </Content>
                    </RightColumn>
                </GridColumn>
            </Grid>
            <ApiErrorAlert error={webinarFetchError} dissmiss={resetWebinar} triggerElement={webinarButtonRef} />
        </>
    );
};

export default styled(ClassroomCardContent)`
    ${BlockSkeleton} {
        border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    }
`;
