import React, { FC } from "react";
import styled from "styled-components";

import { Chevron, SvgIcon } from "@sol/icons";
import { IconButton } from "@sol/uikit/buttons";

import SROnly from "../SROnly";

const ExpansionPanelSummaryButton = styled.div<Props>`
    display: flex;
    align-items: center;

    ${SvgIcon} {
        transition: transform 375ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        ${({ expanded }) => ({
            transform: `rotateZ(${expanded ? 0 : "180deg"})`,
        })}
    }
`;

const ExpansionPanelSummaryContent = styled.div`
    flex: 1;
    display: flex;
    min-width: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

type Props = {
    // These props need to be optional cause passed from top level component
    expanded?: boolean;
    toggleExpanded?: () => void;

    isDisabled?: boolean;
    className?: string;
    srOnlyLabel?: string;
};

const ExpansionPanelSummary: FC<Props> = ({
    expanded,
    toggleExpanded,
    children,
    isDisabled,
    className,
    srOnlyLabel,
}) => {
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div data-cy="expansion-panel-summary" onClick={toggleExpanded} className={className}>
            <ExpansionPanelSummaryContent>{children}</ExpansionPanelSummaryContent>

            <ExpansionPanelSummaryButton expanded={expanded}>
                <IconButton
                    variant="secondary"
                    disabled={isDisabled}
                    aria-expanded={!srOnlyLabel ? expanded : undefined}
                    data-cy="expand"
                >
                    {srOnlyLabel && <SROnly>{srOnlyLabel}</SROnly>}
                    <Chevron />
                </IconButton>
            </ExpansionPanelSummaryButton>
        </div>
    );
};

export default styled(ExpansionPanelSummary)`
    display: flex;
    padding: ${({ theme }) => theme.spacing[6]};
    min-height: 48px;
    transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
        cursor: pointer;

        ${ExpansionPanelSummaryButton} {
            ${IconButton} {
                transition: all 0.2s;

                ${SvgIcon} {
                    transition: all 0.2s;
                }
            }
        }
    }
`;
