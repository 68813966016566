import React, { useMemo } from "react";
import styled from "styled-components";

import { IValidationResource, ValidationStatus } from "@sol/sdk";
import { Grid, GridColumn } from "@sol/uikit";

import CardSkillLevelsMetrics from "./CardSkillLevelsMetrics";
import SkillLevelsMetrics from "./SkillLevelsMetrics";

type Props = {
    skillsCount: number;
    validations: IValidationResource[];
    isInCard?: boolean;
    className?: string;
};

const SkillsMetrics = ({ skillsCount, validations, isInCard, className }: Props) => {
    // We have as many skillLevels as skills
    const skillLevelsCount = skillsCount;

    const [validLevels1, validLevels2, validLevels3] = useMemo(() => {
        const validLevels = [0, 0, 0];

        validations.forEach(validation => {
            if (validation.status === ValidationStatus.VALIDATED) {
                validLevels[validation.skillLevel.level - 1] += 1;
            }
        });

        return validLevels;
    }, [validations]);

    const MetricsComponent = isInCard ? CardSkillLevelsMetrics : SkillLevelsMetrics;

    return (
        <Grid as="ul" className={className}>
            <GridColumn as="li" col={4}>
                <MetricsComponent validLevels={validLevels1} levelsCount={skillLevelsCount} level={1} />
            </GridColumn>
            <GridColumn as="li" col={4}>
                <MetricsComponent validLevels={validLevels2} levelsCount={skillLevelsCount} level={2} />
            </GridColumn>
            <GridColumn as="li" col={4}>
                <MetricsComponent validLevels={validLevels3} levelsCount={skillLevelsCount} level={3} />
            </GridColumn>
        </Grid>
    );
};

export default styled(SkillsMetrics)`
    padding: 0;
    margin: 0;

    & ${GridColumn} {
        list-style: none;
    }
`;
