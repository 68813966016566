import React from "react";
import styled from "styled-components";

import { Text } from "../uikit";
import SkillLevelChip, { SkillLevelChipStatus } from "./SkillLevelChip";

const SkillLevelText = styled(Text).attrs({ variant: "button", as: "span" })`
    margin-right: 10px;
    font-weight: 300;
    white-space: nowrap;
`;

const SkillLevelTextBold = styled(SkillLevelText).attrs({ variant: "button", as: "span" })`
    margin-right: 0;
    font-weight: 700;
`;

type Props = {
    level: number;
    levelsCount: number;
    validLevels: number;
    className?: string;
};

const CardSkillLevelsMetrics = ({ level, levelsCount, validLevels, className }: Props) => {
    return (
        <p className={className}>
            <SkillLevelText>
                <SkillLevelTextBold>{validLevels}</SkillLevelTextBold> / {levelsCount}
            </SkillLevelText>
            <SkillLevelChip level={level} status={SkillLevelChipStatus.VALIDATED} hideIcon />
        </p>
    );
};

export default styled(CardSkillLevelsMetrics)`
    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[4]}`};
    display: flex;
    background: ${({ theme }) => theme.palette.white.base};
    border: 1px solid ${({ theme }) => theme.palette.grey.lightest};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    align-items: center;
`;
