import { motion } from "framer-motion";
import isUndefined from "lodash/isUndefined";
import React, { useCallback, useState, ReactElement, FC, createElement, useMemo } from "react";
import styled from "styled-components";

import theme from "../theme";
import useDimensions from "../utils/useDimensions";
import ExpansionPanelDetails from "./ExpansionPanelDetails";
const ExpansionPanelContentWrapper = styled(motion.div)<{ hide: number }>`
    overflow: hidden;
    ${ExpansionPanelDetails} {
        ${({ hide }) => hide && { display: "none" }}
    }
`;

type Props = {
    htmlTag?: string;
    expanded?: boolean;
    onExpandedChange?: (expanded: boolean) => void;
    className?: string;
};

const ExpansionPanel: FC<Props> = props => {
    const { htmlTag = "section", className, children: childrenProp, expanded, onExpandedChange, ...rest } = props;
    const [internalExpanded, setInternalExpanded] = useState(expanded || false);

    const toggleExpanded = useCallback(
        event => {
            if (event) {
                event.preventDefault();
            }
            if (onExpandedChange) {
                onExpandedChange(!expanded);
            } else {
                setInternalExpanded(!internalExpanded);
            }
        },
        [onExpandedChange, expanded, internalExpanded, setInternalExpanded],
    );

    const isExpanded = !isUndefined(expanded) ? expanded : internalExpanded;

    const { ref, height } = useDimensions<HTMLDivElement>({ defaultHeight: 0 });

    const variants = useMemo(
        () => ({
            expanded: {
                height,
            },
            collapsed: {
                height: 0,
            },
        }),
        [height],
    );

    const [hideDetails, setHideDetails] = useState(isExpanded);

    const [summary, ...children] = React.Children.toArray(childrenProp); // DEBT react-object-usage

    return createElement(
        htmlTag,
        { className, ...rest },
        React.cloneElement(summary as ReactElement, {
            expanded: isExpanded,
            toggleExpanded,
        }),
        <ExpansionPanelContentWrapper
            variants={variants}
            transition={theme.transitions.spring.default}
            initial={isExpanded ? "expanded" : "collapsed"}
            animate={isExpanded ? "expanded" : "collapsed"}
            onAnimationComplete={() => setHideDetails(!isExpanded)}
            hide={hideDetails ? 1 : 0}
        >
            <div ref={ref}>{children}</div>
        </ExpansionPanelContentWrapper>,
    );
};

export default styled(ExpansionPanel)`
    width: 100%;
    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    overflow: hidden;

    box-sizing: border-box;
`;
