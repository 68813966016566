import React from "react";
import styled from "styled-components";

import { Text } from "../uikit";
import SkillLevelChip, { SkillLevelChipStatus } from "./SkillLevelChip";

const Bold = styled(Text)`
    font-weight: bold;
`;

type Props = {
    level: number;
    levelsCount: number;
    validLevels: number;
    className?: string;
};

const SkillLevelsMetrics = ({ level, levelsCount, validLevels, className }: Props) => {
    return (
        <div className={className}>
            <Text>
                <Bold as="span">{validLevels}</Bold> / {levelsCount}
            </Text>
            <SkillLevelChip level={level} status={SkillLevelChipStatus.VALIDATED} hideIcon />
        </div>
    );
};

export default styled(SkillLevelsMetrics)`
    display: flex;
    align-items: center;

    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[6]}`};

    min-width: 342px;
    width: 100%;
    background: ${({ theme }) => theme.palette.white.base};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    & > ${Text} {
        white-space: nowrap;
    }

    ${SkillLevelChip} {
        margin-left: ${({ theme }) => theme.spacing[6]};
    }
`;
