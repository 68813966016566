import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import { useLayoutEffect, useMemo, useRef, useState, RefObject } from "react";

const ResizeObserver =
    typeof window !== "undefined" && (window as any).ResizeObserver
        ? ((window as any).ResizeObserver as typeof ResizeObserverPolyfill)
        : ResizeObserverPolyfill;

const useDimensions = <T = HTMLElement>({
    defaultWidth = 1,
    defaultHeight = 1,
    useDefaults = true,
    borderBox = false,
} = {}): {
    ref: RefObject<T>;
    width?: number;
    height?: number;
} => {
    const ref = useRef<T>(null);

    const [size, setSize] = useState(
        useDefaults
            ? {
                  width: defaultWidth,
                  height: defaultHeight,
              }
            : null,
    );

    useLayoutEffect(() => {
        if (typeof ref !== "object" || ref === null || !(ref.current instanceof Element)) {
            return () => undefined;
        }

        const element = ref.current;

        const resizeObserver = new ResizeObserver(entries => {
            // avoid resizeObserver Cypress bug: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || entries.length < 1) {
                    return;
                }

                const entry = entries[0];

                if (borderBox) {
                    const node = entry.borderBoxSize[0];
                    const newWidth = node.inlineSize;
                    const newHeight = node.blockSize;
                    setSize({ width: newWidth, height: newHeight });
                } else {
                    // `Math.round` is in line with how CSS resolves sub-pixel values
                    const newWidth = Math.round(entry.contentRect.width);
                    const newHeight = Math.round(entry.contentRect.height);
                    setSize({ width: newWidth, height: newHeight });
                }
            });
        });

        resizeObserver.observe(element);

        return () => resizeObserver.unobserve(element);
    }, [ref && ref.current]);

    return useMemo(() => ({ ref, ...size }), [ref, size ? size.width : null, size ? size.height : null]);
};

export default useDimensions;
